// import JS modules to compile
import './init-foundation'
import './wp-foundation'
import '../../styles/scss/style.scss'
import './modules/fancybox'
import './modules/mtiFontTrackingCode'
import './modules/plugins'
import swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import Typed from 'typed.js';


jQuery(function($) {
	$(document).ready(function() {
		if (navigator.userAgent.match(/ipad/i)) {
			var viewport1 = document.querySelector("meta[name=viewport]");
			viewport1.setAttribute('content', 'width=device-width, initial-scale=0.75, user-scalable=0');
		}
		// Match Height of Boxes
		$('#recent_work .row .column, #our_capabilities .row .column, .single-our_capabilities .left_col, .single-our_capabilities .right_col').matchHeight();
		if ($(window).width() > 720) {
			(function() {
				function resize_boxes() {
					var ul_width = $('#our_team .grid-x').width();
					$('#our_team .grid-x .cell').each(function() {
						var li_width = ul_width / 3;
						$(this).css('width', li_width).css('height', li_width);
					});
				}
				resize_boxes();
				$(window).resize(function() {
					resize_boxes();
				});
			}());
		}
		if ($(window).width() < 720) {
			(function() {
				function resize_boxes_mobile() {
					var ul_width_mobile = $('#our_team .grid-x').width();
					$('#our_team .grid-x .cell').each(function() {
						var li_width_mobile = ul_width_mobile / 2;
						$(this).css('width', li_width_mobile).css('height', li_width_mobile);
					});
				}
				resize_boxes_mobile();
				$(window).resize(function() {
					resize_boxes_mobile();
				});
			}());
		}
		// Navicon
		$('#navicon').click(function(){
			$(this).toggleClass('open');
		});
		// Header Scroll
		// Home
		if ($(window).width() > 750) {
			$(".page-id-2 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#recent_work .wrapper').offset().top - 200)
				}, 1000);
			});
		}
		if ($(window).width() < 750) {
			$(".page-id-2 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#recent_work .wrapper').offset().top - 120)
				}, 1000);
			});
		}
		// Who
		if ($(window).width() > 750) {
			$(".page-id-6 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#our_profile').offset().top - 70)
				}, 1000);
			});
		}
		if ($(window).width() < 750) {
			$(".page-id-6 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#our_profile').offset().top - 80)
				}, 1000);
			});
		}
		// What
		if ($(window).width() > 750) {
			$(".page-id-8 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#our_capabilities').offset().top - 80)
				}, 1000);
			});
		}
		if ($(window).width() < 750) {
			$(".page-id-8 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#our_capabilities').offset().top - 50)
				}, 1000);
			});
		}
		// Work
		if ($(window).width() > 750) {
			$(".page-id-10 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#recent_work').offset().top - 160)
				}, 1000);
			});
		}
		if ($(window).width() < 750) {
			$(".page-id-10 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#recent_work').offset().top - 50)
				}, 1000);
			});
		}
		// Contact
		if ($(window).width() > 750) {
			$(".page-id-12 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#wpcf7-f4-p12-o1').offset().top - 200)
				}, 1000);
			});
		}
		if ($(window).width() < 750) {
			$(".page-id-12 .scroll_down").click(function() {
				$('html, body').animate({
					scrollTop: ($('#wpcf7-f4-p12-o1').offset().top - 225)
				}, 1000);
			});
		}
		// Blog
		if ($(window).width() > 750) {
		$(".blog .scroll_down, .archive .scroll_down").click(function() {
			$('html, body.blog').animate({
				scrollTop: ($('#blog_posts').offset().top - 290)
			}, 1000);
		});
		}
		if ($(window).width() < 750) {
		$(".blog .scroll_down, .archive .scroll_down").click(function() {
			$('html, body.blog').animate({
				scrollTop: ($('#blog_posts').offset().top - 220)
			}, 1000);
		});
		}
	});
	// Nav 
	$(".menu-item-18").on('mouseenter mouseleave', function () {
		$(this).find(".sub-menu").stop().fadeToggle();
	});
	$(".menu-item-19").on('mouseenter mouseleave', function () {
		$(this).find(".sub-menu").stop().fadeToggle();
	});
	// Nav
	var userScrolled = false;
	$(window).scroll(function() {
	userScrolled = true;
	});
	setInterval(function() {
	if (userScrolled) {
		if (jQuery(window).width() > 980) {
			if ($(document).scrollTop() > 400) {
				$('.single-our_capabilities .header').addClass('bg');
				$('.top-bar .logo').addClass('swap');
				$('.single-our_capabilities .menu a, .single-our_capabilities .menu .current_page_parent > a').addClass('change');
			} else {
				$('.single-our_capabilities .header').removeClass('bg');
				$('.top-bar .logo').removeClass('swap');
				$('.single-our_capabilities .menu a, .single-our_capabilities .menu .current_page_parent > a').removeClass('change');
			}
		}
		userScrolled = false;
	}
	}, 2000);

	// $(window).scroll(function() {
	// 	if (jQuery(window).width() > 980) {
	// 		if ($(document).scrollTop() > 400) {
	// 			$('.single-our_capabilities .header').addClass('bg');
	// 			$('.top-bar .logo').addClass('swap');
	// 			$('.single-our_capabilities .menu a, .single-our_capabilities .menu .current_page_parent > a').addClass('change');
	// 		} else {
	// 			$('.single-our_capabilities .header').removeClass('bg');
	// 			$('.top-bar .logo').removeClass('swap');
	// 			$('.single-our_capabilities .menu a, .single-our_capabilities .menu .current_page_parent > a').removeClass('change');
	// 		}
	// 	}
	// });
	// $(window).scroll(function() {
	// 	if (jQuery(window).width() > 980) {
	// 		if ($(document).scrollTop() > 400) {
	// 			$('.top-bar .logo').addClass('swap');
	// 		} else {
	// 			$('.top-bar .logo').removeClass('swap');
	// 		}
	// 	}
	// });
	// if (jQuery(window).width() > 980) {
	// 	setTimeout(function(){
	// 		$(".top-bar .logo").addClass('swap', function () {
	// 	});
	//  	}, 4800);
	// }
	// Trim Pagination Titles
	$(".pagination a span").each(function() {
		if ($(this).text().length > 30) $(this).text($(this).text().substring(0, 30) + '...');
	});
	// Make boxes clickable  
	$("#recent_work .column, #our_capabilities .column").click(function() {
		window.location = $(this).find("a").attr("href");
		return false;
	});
	// Clear fields on click 
	var el = $('input[type="text"], input[type="email"], textarea');
	el.focus(function(e) {
		if (e.target.value == e.target.defaultValue) e.target.value = '';
	});
	el.blur(function(e) {
		if (e.target.value === '') e.target.value = e.target.defaultValue;
	});
	// Team Bios 
	// $('.team').click(function(e) {
	// 	e.stopPropagation();
	// 	$(".fade_to_blackish").stop().fadeIn(300);
	// 	$(this).find(".bio").stop().fadeIn(300);
	// 	//$(this).find(".name").stop().fadeOut(300);
	// 	$(this).addClass('visible');
	//  });
	//  $('.close').click(function(e) {
	// 	e.stopPropagation();
	// 	$('.fade_to_blackish').fadeOut(300);
	// 	$('.bio').fadeOut(300);
	// 	//$('.name').fadeIn(300);
	// 	$('.team').removeClass('visible');
	//  });
	// Number Counter
	$('.counter').isInViewport(function () {
		(function ($) {
			$.fn.countTo = function (options) {
				options = options || {};
				return $(this).each(function () {
					var settings = $.extend({}, $.fn.countTo.defaults, {
						from: $(this).data('from'),
						to: $(this).data('to'),
						speed: $(this).data('speed'),
						refreshInterval: $(this).data('refresh-interval'),
						decimals: $(this).data('decimals')
					}, options);
					var loops = Math.ceil(settings.speed / settings.refreshInterval),
						increment = (settings.to - settings.from) / loops;
					var self = this,
						$self = $(this),
						loopCount = 0,
						value = settings.from,
						data = $self.data('countTo') || {};
					$self.data('countTo', data);
					if (data.interval) {
						clearInterval(data.interval);
					}
					data.interval = setInterval(updateTimer, settings.refreshInterval);
					render(value);
					function updateTimer() {
						value += increment;
						loopCount++;
						render(value);
						if (typeof(settings.onUpdate) == 'function') {
							settings.onUpdate.call(self, value);
						}
						if (loopCount >= loops) {
							$self.removeData('countTo');
							clearInterval(data.interval);
							value = settings.to;
							if (typeof(settings.onComplete) == 'function') {
								settings.onComplete.call(self, value);
							}
						}
					}
					function render(value) {
						var formattedValue = settings.formatter.call(self, value, settings);
						$self.html(formattedValue);
					}
				});
			};
			$.fn.countTo.defaults = {
				from: 0,
				to: 0,
				speed: 1000,
				refreshInterval: 100,
				decimals: 0,
				formatter: formatter,
				onUpdate: null,
				onComplete: null
			};
			function formatter(value, settings) {
				return value.toFixed(settings.decimals);
			}
		}(jQuery));
		jQuery(function ($) {
		  $('.count-number').data('countToOptions', {
			formatter: function (value, options) {
			  return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
			}
		  });
		  $('.timer').each(count);  
		  function count(options) {
			var $this = $(this);
			options = $.extend({}, options || {}, $this.data('countToOptions') || {});
			$this.countTo(options);
		  }
		});
	});
	// Masonry
	$(window).scroll( function(){
        $('.masonry-css-item').each( function(i){
            var bottom_of_object = $(this).offset().top + 100;
            var bottom_of_window = $(window).scrollTop() + $(window).height();
            if( bottom_of_window > bottom_of_object ){
                $(this).animate({'opacity':'1', 'margin-top':'0'},500);
            }
        }); 
    });
	
	// Home Typing
	if( $('body.home').length) {
		var options = {
			strings: ["branding &amp; design", "web design &amp; development", "content creation", "digital marketing"],
			typeSpeed: 90,
			backSpeed: 40,
			smartBackspace: true,
			backDelay: 1800,
			loop: true,
			loopCount: Infinity,
			showCursor: false,
		}
		var typed = new Typed(".page-id-2 #type", options);
	}
	// Work Slideshows
	var workSlider = new swiper ('.swiper-container', {
		direction: 'horizontal',
		autoHeight: false,
		loop: true,
		parallax: true,
		watchOverflow: true,
		fadeEffect: {
		crossFade: true
		},  
		effect: 'fade',
		navigation: {
		  	nextEl: '.swiper-button-next',
		  	prevEl: '.swiper-button-prev',
		},
	})
});