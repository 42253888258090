import fancybox from '@fancyapps/fancybox'

jQuery(document).ready(function() {
    jQuery('[data-fancybox]').fancybox({
        vimeo : {
            color : 'f00'
        }
    });
});


